<template>
  <hb-modal
    v-model="show"
    size="medium"
    title="Attach File"
    @close="onClose"
  >
    <template v-slot:content>
      <HbFileInput class="ma-4" v-model="files" placeholder="Select to Attach a File" :allowMultiple="allowMultiple" />
    </template>

    <template v-slot:right-actions>
      <hb-btn :disabled="uploadIsDisabled" color="primary" @click="getAttachments(files)">Upload</hb-btn>
    </template>
  </hb-modal>
</template>

<script>
export default {
  name:'SendEmail',
  data () {
    return {
      files: []
    }
  },
  props: ['to','subject','from','show','onSend','onClose', 'getAttachments', 'allowMultiple'],
  computed: {
    uploadIsDisabled () {
      return this.files.length ? false: true
    }
  }
}
</script>


<style scoped>
.label-div {
  background-color: #F9FAFB;
  height: 48px;
  color: #101318;
}
</style>