<template>
  <v-card class="payment-data hb-elevation-medium" :disabled="isDropdownDisabled" :class="{'no-border-all' : moveout_settings }" style="background: white">
    <v-container class="payment-data-container" >
      <v-row class="payment-data-header" justify="space-between">
        <div v-if="!openInvoicesMoveOut" class="hb-font-header-3-medium">Payment Method</div>
        <v-row v-if="openInvoicesMoveOut"><v-col cols="6"><span class="hb-font-header-3-medium">Full Payment</span></v-col><v-col cols="6" class="text-right">Total Balance Due <strong class="pl-3">{{amount | formatMoney}}</strong></v-col></v-row>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="4" :class="{'card-options': !$vuetify.breakpoint.xs, 'px-4': $vuetify.breakpoint.xs}">
           <payment-property-selector v-if="showP2PSelector" />
          <v-radio-group v-model="payment_type" :mandatory="false" @change="handlePaymentType">
            <v-radio value="reader" v-if="connectionDevices && connectionDevices.length">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>Card Reader
              </template>
            </v-radio>
            <v-radio value="card"  v-if="cardProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>Credit/Debit
              </template>
            </v-radio>
            <v-radio value="Eftpos"  v-if="eftposProcessor" :disabled="auto_charge">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-credit-card-outline</hb-icon>EFTPOS
              </template>
            </v-radio>
            <v-radio value="directdebit"  v-if="directDebitProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Direct Debit
              </template>
            </v-radio>
            <v-radio value="ach" v-if="achProcessor">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>ACH/E-Check
              </template>
            </v-radio>
            <v-radio value="cash" :disabled="auto_charge">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-cash-multiple</hb-icon>Cash
              </template>

            </v-radio>
            <v-radio value="check" :disabled="auto_charge">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>Check
              </template>

            </v-radio> 
            <v-radio v-if="shouldShowGiftCard" :value=PAYMENTS.PAYMENT_METHODS.GIFTCARD.VALUE :disabled="auto_charge">
              <template v-slot:label>
                <hb-icon class="mr-3" color="#101318">mdi-checkbook</hb-icon>{{PAYMENTS.PAYMENT_METHODS.GIFTCARD.TEXT}}
              </template>

            </v-radio>

          </v-radio-group>
          <v-row v-if="auto_charge">
            <v-col cols="12" class="hb-text-light pt-0">
              You can only enroll a tenant in AutoPay with a credit or debit card.
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" class="ma-0 pa-0" v-if="$vuetify.breakpoint.xs">
          <v-divider class="pa-0 ma-0"></v-divider>
        </v-col>
        <v-col cols="12" sm="8" class="payment-method-holder">

          <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
          <cash :payment_type="payment_type" v-if="payment_type === 'check' || payment_type === 'cash'" ref="cashCheck"></cash>
          <eftpos :payment_type="payment_type" v-if="payment_type === 'Eftpos'" ref="Eftpos" ></eftpos>
          <gift-card :payment_type="payment_type" v-if="payment_type === 'giftcard'" ref="giftCard"></gift-card>
          <authnet-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'authorizenet'" ref="authnetCard" :paymentSource="paymentSource"></authnet-card>
          <!-- <authnet-ach  :auto_charge="auto_charge" :payment_methods="payment_methods" @payment="processPayment"  v-if="payment_type === 'ach' && achProcessor === 'authorizenet'"></authnet-ach> -->
          <forte-ach :auto_charge="auto_charge" v-if="payment_type === 'ach' && achProcessor === 'forte'" ref="forteAch"></forte-ach>
          <tsys-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'tsys'" ref="tsysCard"></tsys-card>
          <tsys-ach :ten_address="tenantAddress" :auto_charge="auto_charge"  v-if="payment_type === 'ach' && achProcessor === 'tsys'" ref="tsysAch"></tsys-ach>
          <tenant-payments-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'tenant_payments'" ref="tenantPaymentsCard" :paymentSource="paymentSource"></tenant-payments-card>
          <fat-zebra-card :ten_address="tenantAddress" :auto_charge="auto_charge" v-if="payment_type === 'card' && cardProcessor === 'fatzebra'" ref="fatzebraCard" :paymentSource="paymentSource"></fat-zebra-card >
          <fat-zebra-direct-debit :ten_address="tenantAddress" :auto_charge="auto_charge"  v-if="payment_type === 'directdebit' && directDebitProcessor === 'fatzebra'" ref="fatzebraDirectDebit"></fat-zebra-direct-debit>
          <tenant-payments-ach :ten_address="tenantAddress" :auto_charge="auto_charge"  v-if="payment_type === 'ach' && achProcessor === 'tenant_payments'" ref="tenantPaymentsAch"></tenant-payments-ach>
          <tsys-reader :devices="connectionDevices" :auto_charge="auto_charge" v-if="payment_type === 'reader'" ref="cardReader"></tsys-reader>
          <!-- <tenant-payments-reader :devices.sync="connectionDevices" :payment_method.sync="payment_method" :payment.sync="payment" :auto_charge="auto_charge" :property_id="property_id" :payment_methods="payment_methods" v-if="payment_type === 'reader'"></tenant-payments-reader>  -->
          <account-credit v-if="payment_type === 'credit'" ref="accountCredit"></account-credit>

          <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success" style="position:absolute;bottom:.5%;right:1.6%;width:40%"></status>

        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>


<script type="text/babel">
    import Status from '../includes/Messages.vue';
    import FatZebraCard from "./PaymentMethods/FatzebraCard";
    import FatZebraDirectDebit from "./PaymentMethods/FatzebraDirectDebit";
    import AuthnetCard from "./PaymentMethods/AuthnetCard";
    import AccountCredit from "./PaymentMethods/AccountCredit";
    import Cash from "./PaymentMethods/Cash";
    import Eftpos from "./PaymentMethods/Eftpos";
    import GiftCard from "./PaymentMethods/GiftCard";
    import TsysCard from "./PaymentMethods/TsysCard";
    import TsysReader from "./PaymentMethods/TsysReader";
    import TsysAch from "./PaymentMethods/TsysAch";
    import ForteAch from "./PaymentMethods/ForteAch";
    import PaymentPropertySelector from "./PaymentMethods/PaymentPropertySelector";

    import TenantPaymentsCard from "./PaymentMethods/TenantPaymentsCard";
    import TenantPaymentsAch from "./PaymentMethods/TenantPaymentsAch";
    //import TenantPaymentsReader from "./PaymentMethods/TenantPaymentsReader";
    import { EventBus } from '../../EventBus.js';

    import {PAYMENTS} from '@/constants/payments.js';
    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import { notificationMixin } from  '../../mixins/notificationMixin.js';

    export default {
        name: 'CollectPayment',
        mixins: [ notificationMixin ],
        components: {
            Eftpos,
            AuthnetCard,
            Status,
            Cash,
            GiftCard,
            TsysCard,
            TsysReader,
            TsysAch,
            ForteAch,
            TenantPaymentsCard,
            TenantPaymentsAch,
            FatZebraCard,
            FatZebraDirectDebit,
            //TenantPaymentsReader,
            AccountCredit,
            PaymentPropertySelector
        },
        data: () => ({
            payment_type: '',
            step: 1,
            close_confirm: false,
            panel: [0, 1],
            valid: false,
            unit_id: '',
            drawer: false,
            model: null,
            search: null,
            isDropdownDisabled: false,
            credit: {},
            summary_panel: 'facility',
            payment_amount: 0,
            partial_payment: false,
            allowed_active_drawer_components: ['showSellMerchandise', 'showMakePayment','showTransfer']
        }),
        props: ["contact_id", "property_id", "amount", 'moveout_settings', 'tenant_address', 'default_payment_type', 'openInvoicesMoveOut', 'getSourceProperty', 'paymentSource'],
        created(){
                    this.PAYMENTS = PAYMENTS;
          if(this.connections && this.connections.length){
            this.payment_type = this.connectionDevices && this.connectionDevices.length ? 'reader' : 'card';
          }else{
            this.payment_type = 'cash'
          }
          this.handlePaymentType();
          EventBus.$on('disable_reader_dropdown', ()=>{
              this.isDropdownDisabled = true;
        });
        EventBus.$on('enable_reader_dropdown', ()=>{
              this.isDropdownDisabled = false;
        });
        },
        mounted(){
        },
        destroyed(){
          this.isDropdownDisabled = false;
          EventBus.$off('enable_reader_dropdown',  this.isDropdownDisabled);
          EventBus.$off('disable_reader_dropdown', this.isDropdownDisabled);
    
        },
        filters:{
        },
        computed:{
          ...mapGetters({
            contact: 'paymentsStore/getContact',
            connections: 'paymentsStore/getPropertyConnections',
            activeDrawerComponent: 'navigationStore/activeDrawerComponent',
            showP2PSelector: 'paymentsStore/showP2PSelector',
            getPaymentSource:'paymentsStore/getPaymentSource',
            shouldShowGiftCard:'paymentsStore/shouldShowGiftCard',
            paymentData: 'paymentsStore/getPaymentInformation',
            payment_methods: 'paymentsStore/getContactPaymentMethods',
            payment_method: 'paymentsStore/getPaymentMethod',
            payment: 'paymentsStore/getPayment',
            leases: 'paymentsStore/getLeases',
          }),
            tenantAddress(){
              if(!this.tenant_address){
                return this.contact && this.contact.Addresses;
              }
              return this.tenant_address;
            },
            cardReaderProcessor(){
                let processor = this.connections.find(c => c.type === 'reader' );
                return processor ? processor.name : null;
            },
            eftposProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'eftpos');
                return processor ? processor.name : null;
            },
            cardProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'card' );
                return processor ? processor.name : null;
            },
            achProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'ach' );
                return processor ? processor.name : null;
            },
            directDebitProcessor(){
                let processor = this.connections && this.connections.find(c => c.type === 'directdebit' );
                return processor ? processor.name : null;
            },
            paymentMethods(){
                let pm = [];
                this.leases.filter(l => l.pay).map(l => {
                    if(!l.PaymentMethods) return;
                    return l.PaymentMethods.map(p => {
                        pm.push(p);
                    })
                });
                return pm;
            },
            connectionDevices() {
              let processor = this.connections && this.connections.find(c => c.type === 'card' );
                return processor ? processor.Devices : null;
            },
            auto_charge() {
              return this.leases && this.leases.length && this.leases.filter(l => l.auto_charge).length ? true : false;
            }
        },
        methods:{
          ...mapActions({
              fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',
              setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
            }),
            ...mapMutations({
              setLeaseProperty: 'paymentsStore/setLeaseProperty',
              setPaymentInformation: 'paymentsStore/setPaymentInformation',
              setPayment: 'paymentsStore/setPayment',
              setPaymentMethod: 'paymentsStore/setPaymentMethod',
              setPaymentObject: 'paymentsStore/setPaymentObject',
              setPaymentMethodObject: 'paymentsStore/setPaymentMethodObject',
              setPaymentInformationObject: 'paymentsStore/setPaymentInformationObject',
              setDisablePaymentButton: 'paymentsStore/setDisablePaymentButton',
              ivrPaymentType:'charmCallStore/ivrPaymentType'
            }),
            resetPaymentInfo(){
                this.payment_type = null;
            },
            async getPaymentMethodOptions(property_id) {
              this.setDisablePaymentButton({value: true});
              await this.fetchPropertyConnections({
                propertyID: property_id || this.property_id
              });

              if(!this.connections.length){
                this.showMessageNotification({type: 'error', description: "This account is not set up to accept online payments."});
              }
              this.setDisablePaymentButton({value: false});
            },
            close(){
                this.$emit('close');
            },
            async processPayment(data){

                let payment_info = data.payment;
                payment_info.contact_id = this.contact_id;
                payment_info.property_id = this.property_id;

                if(data.PaymentMethod){
                    payment_info.PaymentMethod = data.PaymentMethod;
                }

                this.$emit('payment', payment_info);

            },
            setDefaultRefName() {
              let tenant_name = this.contact.first + ' ' + this.contact.last
              this.payment.ref_name = tenant_name || ''
            },
            
            handlePaymentType(){
              let type = this.payment_type;
              this.setPayment({property : 'source', propertyValue: 'In-store'});
              this.setPayment({property : 'type', propertyValue: type === 'reader' ? 'card' : type });
              this.setPaymentMethod({property : 'type', propertyValue: type === 'reader' ? 'card' : type});

              if(type === 'cash' || type === 'check'){
                this.setPayment({property : 'payment_method_id', propertyValue: null});
                if(!this.payment.ref_name) this.setPayment({property : 'ref_name', propertyValue: this.contact ? (this.contact.first + ' ' + this.contact.last) : ''});
              }
              if(type === 'card' || type === 'ach' || type === 'directdebit'){
                this.setPaymentMethod({property: 'save_to_account', propertyValue: this.auto_charge});
              }
              if(type === 'giftcard'){
                this.setPayment({property : 'payment_method_id', propertyValue: null});
                this.setPayment({property : 'source', propertyValue: PAYMENTS.PAYMENTS.SOURCE});
                if(!this.payment.ref_name) this.setPayment({property : 'ref_name', propertyValue: this.contact ? (this.contact.first + ' ' + this.contact.last) : ''});
              }
              if(type !== 'credit'){
                  this.setPayment({property : 'id', propertyValue: null});
              }
              if(type != 'reader') {
                this.setPaymentMethod({property : 'device_id', propertyValue: ''});
                this.setPaymentMethod({property : 'connection_id', propertyValue: ''});
              }
            },
            
            validateDataFields(){
              if(this.payment_type === 'reader' || this.payment_type === 'credit') return true;
              if(this.payment_type === 'check' || this.payment_type === 'cash') return this.$refs.cashCheck.validateFields();
              if(this.payment_type === 'giftcard') return this.$refs.giftCard.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'authorizenet') return this.$refs.authnetCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'forte') return this.$refs.forteAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'tsys') return this.$refs.tsysCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'tsys') return this.$refs.tsysAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'tenant_payments') return this.$refs.tenantPaymentsCard.validateFields();
              if(this.payment_type === 'ach' && this.achProcessor === 'tenant_payments') return this.$refs.tenantPaymentsAch.validateFields();
              if(this.payment_type === 'card' && this.cardProcessor === 'fatzebra') return this.$refs.fatzebraCard.validateFields();
              if(this.payment_type === 'directdebit' && this.directDebitProcessor === 'fatzebra') return this.$refs.fatzebraDirectDebit.validateFields();
              if(this.payment_type === 'Eftpos') return this.$refs.Eftpos.validateFields();
            }

        },
        watch:{          
          payment_type(newValue, oldValue) {             
              this.ivrPaymentType(newValue);
             },
            connectionDevices() {
              if(this.connectionDevices && this.connectionDevices.length) {
                this.payment_type = 'reader';
              } else {
                this.payment_type = this.connections && this.connections.length ? 'card' : 'cash';
              }
              this.handlePaymentType();
            },
            auto_charge(val) {
              if(!val && this.connectionDevices && this.connectionDevices.length ) {
                this.payment_type = 'reader';
              } else {
                this.payment_type = 'card';
              }
              this.handlePaymentType();
            },
        }
    }

</script>



<style scoped>
  .new-tenant-walkthrough-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .new-tenant-walkthrough{
    background: #F9FAFB;
    width: 100%;
  }

  .new-tenant-walkthrough .v-expansion-panel{
    margin-bottom: 10px;
    margin-top: 0;
  }

  .new-tenant-info-panel{
    flex: 0 0 320px;
    height: 100%;
    padding: 30px 50px;
    background-color: #F9FAFB;

    border-left: 1px solid #E1E6EA;
  }
  .walkthrough-body{
    flex: 1;
  }
  .section-header{
    background: white;
  }

  .user-info{
    padding-left: 50px;
    padding-right: 5px;
    background-color: white;
    border-bottom: 1px solid #E1E6EA;
  }


  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;
    font-weight: bold;
  }

  .invoice_display{
    padding:  10px 0px;
    font-size: 14px;
  }

  .invoice-line-row{
    margin-bottom: 0;
    padding: 0px 20px;
  }
  .invoice-line-row .col {
    padding: 0;
  }

  .invoice-total-row >.col{
    padding: 10px;
  }

  .invoice-row .caption{
    color: #637381
  }

  .invoice-row > .col{
    padding-bottom: 0;
  }

  .invoice-line-row.total{
    border-top: 1px solid #C4CDD5;
    margin-top: 20px;
  }
  .invoice-line-row.total > .col{
    padding-top: 15px;
  }

  .payment-data{
    border: 1px solid #DFE3E8;
  }

  .payment-data-header .v-input--checkbox,
  .form-label .v-input--checkbox{
    margin-top: 0;
    padding-top: 0;
  }

  .payment-data-header{
    border-bottom: 1px solid #DFE3E8;
    padding: 15px 20px;
    margin-bottom: 0;
  }

  .container.payment-data-container {
    padding: 0 12px;
  }

  .card-options{
    border-right: 1px solid #C4CDD5;
    padding: 10px;
    font-size: 14px;
  }

  .payment-method-holder{
    padding: 10px 20px;
  }
  .no-border-all{
    border: none;
  }


</style>
